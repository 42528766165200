import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["fablab"],
  "status": ["depricated"],
  "title": "FabLab VØ",
  "subtitle": "Digital fabrication laboratory and art space in the office community Silicon VØ",
  "description": "",
  "years": ["2019", "2020"],
  "institutions": ["silicon vø"],
  "location": "vorupør",
  "keywords": {
    "topics": ["fablab", "makerspace"],
    "tools": ["", ""]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer"
  }, {
    "name": "Laura Stokbro",
    "role": "Designer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Content`}</h2>
    <p>{`Starting a digital fabrication laboratory and art space in the office community Silicon VØ. The project is currently in the funding stage.
With a surfer friend of mine we tried to start a FabLab in the co-workspace Silicon VØ. Both for private hobby enthusiast. But mainly to do educational workshops both for youth and people from conventional industries in hope of inspiring and seeing new opportunities.`}</p>
    <h2>{`Activities`}</h2>
    <ul>
      <li parentName="ul">{`Funding`}</li>
      <li parentName="ul">{`Projecting`}</li>
      <li parentName="ul">{`Illustration`}</li>
      <li parentName="ul">{`Networking`}</li>
    </ul>
    <h2>{`Mentionable Results`}</h2>
    <p>{`We managed to raise 100.000 DKK but fell short of the goal, and COVID-19 made things even more difficult.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      